import { render, staticRenderFns } from "./lifestyle-imagery.vue?vue&type=template&id=2ebd7adc&scoped=true&lang=pug&"
import script from "./lifestyle-imagery.vue?vue&type=script&lang=coffee&"
export * from "./lifestyle-imagery.vue?vue&type=script&lang=coffee&"
import style0 from "./lifestyle-imagery.vue?vue&type=style&index=0&id=2ebd7adc&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ebd7adc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CraftVisual: require('/opt/build/repo/node_modules/@bkwld/cloak/components/craft-visual.coffee').default})
