






















import breakpoints from "library/assets/vars/breakpoints.json"
export default

	props:
		block: Object
		addHeight: Boolean

	data: -> cleanupResponsiveMotion: null

	computed:
		desktopImage: ->
			return unless @block.image && @block.image.length && @block.image[0].landscape?.length
			@block.image[0].landscape

		mobileImage: ->
			return unless @block.image && @block.image.length && @block.image[0].portrait?.length
			@block.image[0].portrait

		computedDesktopImage: -> if !@desktopImage then @mobileImage else @desktopImage

		computedMobileImage: -> if !@mobileImage then @desktopImage else @mobileImage

	mounted: ->
		@cleanupResponsiveMotion = @$setupResponsiveMotion @motionSetup, @motionCleanup, "(min-width: #{breakpoints.mobile}) and (min-height: 700px)"

	destroyed: -> @cleanupResponsiveMotion()

	methods:
		motionSetup: ->

			# Parallax Imagery
			@timelineLifestyle = @$gsap.timeline()
				# Fade in
				.fromTo @$refs.image0, {opacity: 0}, {opacity: 1, ease: 'linear', duration: 0.1}, 0.07
				.fromTo @$refs.image1, {opacity: 0}, {opacity: 1, ease: 'linear', duration: 0.1}, 0.03
				.fromTo @$refs.image2, {opacity: 0}, {opacity: 1, ease: 'linear', duration: 0.1}, 0
				.fromTo @$refs.image3, {opacity: 0}, {opacity: 1, ease: 'linear', duration: 0.1}, 0.07

				# Parallax
				.fromTo @$refs.image0, {yPercent: 0}, {yPercent: -20, ease: 'linear'}, 0
				.fromTo @$refs.image1, {yPercent: 0}, {yPercent: 20, ease: 'linear'}, 0
				.fromTo @$refs.image2, {yPercent: 0}, {yPercent: -30, ease: 'linear'}, 0
				.fromTo @$refs.image3, {yPercent: 0}, {yPercent: 40, ease: 'linear'}, 0

				# Asset fade in
				.fromTo @$refs.asset, {opacity: 0, y: 100}, {duration: 0.3, opacity: 1, y: 0}, 0

			@trigger = @$ScrollTrigger.create(
				trigger: @$el
				start: 'center bottom'
				end: 'center top'
				scrub: 1
				animation: @timelineLifestyle
			)

			@$ScrollTrigger.refresh(true)

		motionCleanup: ->
			@trigger?.kill?()
			@$refs.asset?.style?.transform = "none"

