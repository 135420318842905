

































import breakpoints from "library/assets/vars/breakpoints.json"
import Accordion from 'library/components/globals/accordion'
import SsrCarousel from 'vue-ssr-carousel'
import ssrCarouselCss from 'vue-ssr-carousel/index.css'

export default

	components: {
		Accordion
		SsrCarousel
	}

	props:
		block: Object
		addHeight: Boolean

	data: ->
		config:
			slidesPerPage: 1
			showArrows: false
			showDots: true
			paginateBySlide: true

		useBreakpoints: true

	computed:
		desktopImage: ->
			return unless @block.image && @block.image.length && @block.image[0].landscape?.length
			@block.image[0].landscape

		mobileImage: ->
			return unless @block.image && @block.image.length && @block.image[0].portrait?.length
			@block.image[0].portrait

		computedDesktopImage: -> if !@desktopImage then @mobileImage else @desktopImage

		computedMobileImage: -> if !@mobileImage then @desktopImage else @mobileImage


	mounted: -> @cleanupResponsiveMotion = @$setupResponsiveMotion @motionSetup, @motionCleanup, "(min-width: #{breakpoints.mobile}) and (min-height: 700px)"
	destroyed: -> @cleanupResponsiveMotion()

	methods:
		motionSetup: ->
			@timeline = @$gsap.timeline()
				# Text sections
				.fromTo @$refs.cta, {x: 0, y: -200, opacity: 0}, {x: 0, y: 0, opacity: 1}, 0
				.fromTo @$refs.accordion, {x: 0, y: 200, opacity: 0}, {x: 0, y: 0, opacity: 1}, 0

				# Asset
				.fromTo @$refs.asset, {opacity: 0, y: 100}, {duration: 0.3, opacity: 1, y: 0}, 0

			@trigger = @$ScrollTrigger.create(
				trigger: @$el
				start: 'center bottom'
				end: '+=200'
				scrub: 1
				animation: @timeline
			)

			@$ScrollTrigger.refresh(true)

		motionCleanup: ->
			@trigger?.kill?()
			@$refs.asset?.style?.transform = "none"
			@$refs.cta?.style?.transform = "none"
			@$refs.accordion?.style?.transform = "none"

