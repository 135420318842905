import { render, staticRenderFns } from "./product-and-accordion.vue?vue&type=template&id=6c2cb91d&scoped=true&lang=pug&"
import script from "./product-and-accordion.vue?vue&type=script&lang=coffee&"
export * from "./product-and-accordion.vue?vue&type=script&lang=coffee&"
import style0 from "./product-and-accordion.vue?vue&type=style&index=0&id=6c2cb91d&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c2cb91d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CraftVisual: require('/opt/build/repo/node_modules/@bkwld/cloak/components/craft-visual.coffee').default,SmartLink: require('/opt/build/repo/nuxt-app/components/globals/smart-link.coffee').default,Btn: require('/opt/build/repo/nuxt-app/components/globals/btn/btn.vue').default,Accordion: require('/opt/build/repo/library/components/globals/accordion.vue').default,SsrCarousel: require('/opt/build/repo/node_modules/vue-ssr-carousel/index.js').default})
