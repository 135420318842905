























import LifestyleImagery from '~/components/blocks/lifestyle-imagery'
import ProductAndAccordion from '~/components/blocks/product-and-accordion'
import breakpoints from "library/assets/vars/breakpoints.json"

export default

	components: {
		LifestyleImagery
		ProductAndAccordion
	}

	props: block: Object

	computed:
		firstAsset: -> @block.children[0].image[0]
		secondAsset: -> @block.children[1].image[0]


		firstAssetMobile: -> @firstAsset.portrait[0]
		firstAssetDesktop: -> @firstAsset.landscape[0]
		secondAssetMobile: -> @secondAsset.portrait[0]
		secondAssetDesktop: -> @secondAsset.landscape[0]

	mounted: -> @cleanupResponsiveMotion = @$setupResponsiveMotion @motionSetup,
		@motionCleanup, "(min-width: #{breakpoints.mobile}) and (min-height: 700px)"
	destroyed: -> @cleanupResponsiveMotion()

	methods:
		motionSetup: ->
			@timeline = @$gsap.timeline()
				.fromTo @$refs.phones, {opacity: 0, yPercent: 5}, {opacity: 1, yPercent: 0, duration: 0.2}, 0

			@trigger = @$ScrollTrigger.create(
				trigger: @$el
				start: 'center-=400px bottom'
				end: 'center-=300 bottom'
				animation: @timeline
			)
			@$ScrollTrigger.refresh(true)

			@timeline2 = @$gsap.timeline()
				.fromTo @$refs.first, {opacity: 1}, {opacity: 0, duration: 1}, 0
				.fromTo @$refs.second, {opacity: 0, yPercent: 5}, {opacity: 1, yPercent: 0}, 0.2

			@trigger2 = @$ScrollTrigger.create(
				trigger: @$el
				start: 'bottom-=400px bottom'
				end: 'bottom bottom'
				animation: @timeline2
			)

		motionCleanup: ->
			@trigger?.kill?()
			@trigger2?.kill?()
			@$refs.phones?.style?.transform = "none"

