




















import 'vue-height-tween-transition/index.css'
import HeightTween from 'vue-height-tween-transition'
import { kebabCase } from 'library/helpers/formatting'

export default

	components: {
		HeightTween
	}

	props:
		accordion: Array

		# The behavior of the accordions. "single" allows one accordion to be open at a moment
		# "multi" allows multiple accordions to stay open at once
		behavior:
			type: String
			default: 'single'
			validator: (val) -> val in ['single', 'multi']

		# Compact is used in the Story Scroll block. It has dark blue borders, and no padding in the pane. Toggle button is plus/minus
		# Wide is used in the FAQ Accordion block. It has gray borders, and padding in the pane. Toggle button is plus/minus
		# No-border is used in the FAQ Listing block. It has no borders. Toggle button is a chevron
		layout:
			type: String
			default: 'compact'
			validator: (val) -> val in ['compact', 'wide', 'no-border']

	data: ->
		openPanel: if @behavior == 'single' then null else (false for i in @accordion)

	computed:
		classes: -> [kebabCase @layout || 'compact', 'layout']

		isPlus: -> return true if @layout != 'no-border'
	methods:
		toggleAccordion: (id) -> if @behavior == 'single' then @toggleSingle(id) else @toggleMulti(id)

		toggleSingle: (id) ->
			if @openPanel == id
			then @openPanel = null
			else @openPanel = id

		toggleMulti: (id) ->
			@$set(@openPanel, id, !@openPanel[id])

		accordionClass: (id) -> { open: @accordionOpen(id) }

		accordionOpen: (id) -> if @behavior == 'single' then id == @openPanel else @openPanel[id]

